var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { getActivityChartTickValues, getFallChartTickValues, getLOSChartTickValues, getScoreAndTarget, } from './component/ZoneChart/ZoneChart.types';
import ZoneChart from './component/ZoneChart/ZoneChart.component';
import { useQuery } from 'utilities/react-query';
import { useParams } from 'react-router-dom';
import { MONTHS, facilitySlugs } from 'globals/global.constants';
import { getActivityGraphService } from 'services/facilityGraphServices/activityGraphService/activityGraph.service';
import { showError } from 'store/commonReducer/common.action';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import FacilityDetailHeader from 'common/FacilityDetailHeader/FacilityDetailHeader';
import { getFallGraphService } from 'services/facilityGraphServices/fallGraphService/fallGraph.service';
import { getLOSGraphService } from 'services/facilityGraphServices/losGraphService/losGraph.service';
import facilityDashboard from './FacilityDashboard.style';
import Filters from './component/Filters/Filters.component';
import { getResidentCountService } from 'services/facilityGraphServices/residentCountService/residentCount.service';
import { CHART_ZONES, FACILITY_TYPES, } from 'globals/enums';
import { capitalizeFirstLetter } from 'globals/global.functions';
import useGetHolisticGraph from './useGetHolisticGraph';
import WellbeingGraph from './WellbeingGraph';
import useGetWellbeingGraph from './useGetWellbeingGraph';
// import UCLALonelinessAssessment from './UCLALonelinessAssessment';
// import useGetUCLALonelinessGraph from './useGetUCLALonelinessGraph';
import InfoIcon from '@mui/icons-material/Info';
// import FallsAndHospitalizationAssessment from './FallsAndHospitalizationAssessment';
// import useGetFallsAndHospitalizationGraph from './useGetFallsAndHospitalizationGraph';
var FacilityDashboard = function () {
    var params = useParams();
    var classes = facilityDashboard().classes;
    var dispatch = useAppDispatch();
    var agentFacilityType = useAppSelector(function (state) { var _a; return (_a = state.auth.facilityDetails) === null || _a === void 0 ? void 0 : _a.facility_type; });
    if (agentFacilityType === 'home_care') {
        agentFacilityType = 'memory_care';
    }
    var facilityTypeOption = React.useMemo(function () {
        return agentFacilityType === null || agentFacilityType === void 0 ? void 0 : agentFacilityType.split('_').map(function (x) { return capitalizeFirstLetter(x, true); }).join(' ');
    }, [agentFacilityType]);
    if (facilityTypeOption === 'Memory Care') {
        facilityTypeOption = 'Home Care';
    }
    var _a = useState({
        months: 3,
        zones: { overall: true, blue: false, green: false, gold: false },
        facilityType: '',
    }), graphState = _a[0], setGraphState = _a[1];
    var facilityTypes = useAppSelector(function (state) {
        return state.corporateAndFacilities.facilityManagementData.selectedFacility
            .facilityType;
    });
    var facilityType = React.useMemo(function () {
        var _a;
        return (_a = graphState === null || graphState === void 0 ? void 0 : graphState.facilityType) === null || _a === void 0 ? void 0 : _a.split(' ').map(function (x) { return x.toLowerCase(); }).join('_');
    }, [graphState.facilityType]);
    if (facilityType === 'home_care') {
        facilityType = 'memory_care';
    }
    //activity graph query
    var _b = useQuery({
        queryKey: ['facilityActivityGraph'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var apiParams, data_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!facilityType) return [3 /*break*/, 2];
                        apiParams = {
                            facility_id: params[facilitySlugs.facilityHomePage] || '',
                            duration: graphState.months,
                            facility_type: facilityType,
                        };
                        return [4 /*yield*/, getActivityGraphService(apiParams)];
                    case 1:
                        data_1 = _a.sent();
                        return [2 /*return*/, data_1];
                    case 2: return [2 /*return*/, {}];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
        enabled: !!facilityType &&
            (graphState.facilityType === FACILITY_TYPES.INDEPENDENT_LIVING ||
                graphState.facilityType === FACILITY_TYPES.MEMORY_CARE),
    }), activityGraphData = _b.data, isActivityGraphLoading = _b.isLoading, isActivityGraphRefetching = _b.isRefetching, refetchActivityGraph = _b.refetch;
    //fall detection query
    var _c = useQuery({
        queryKey: ['facilityFallGraph'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var apiParams, data_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!facilityType) return [3 /*break*/, 2];
                        apiParams = {
                            facility_id: params[facilitySlugs.facilityHomePage] || '',
                            duration: graphState.months,
                            facility_type: facilityType,
                        };
                        return [4 /*yield*/, getFallGraphService(apiParams)];
                    case 1:
                        data_2 = _a.sent();
                        return [2 /*return*/, data_2];
                    case 2: return [2 /*return*/, {}];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
        enabled: !!facilityType && graphState.facilityType === FACILITY_TYPES.MEMORY_CARE,
    }), fallGraphData = _c.data, isFallGraphLoading = _c.isLoading, isFallGraphRefetching = _c.isRefetching, refetchFallGraph = _c.refetch;
    // los graph query
    var _d = useQuery({
        queryKey: ['facilityLOSGraph'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var apiParams, data_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!facilityType) return [3 /*break*/, 2];
                        apiParams = {
                            facility_id: params[facilitySlugs.facilityHomePage] || '',
                            duration: graphState.months,
                            facility_type: facilityType,
                        };
                        return [4 /*yield*/, getLOSGraphService(apiParams)];
                    case 1:
                        data_3 = _a.sent();
                        return [2 /*return*/, data_3];
                    case 2: return [2 /*return*/, {}];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
        enabled: !!facilityType &&
            (graphState.facilityType === FACILITY_TYPES.INDEPENDENT_LIVING ||
                graphState.facilityType === FACILITY_TYPES.MEMORY_CARE),
    }), losGraphData = _d.data, isLOSGraphLoading = _d.isLoading, isLOSGraphRefetching = _d.isRefetching, refetchLOSGraph = _d.refetch;
    // resident count based on filters query
    var _e = useQuery({
        queryKey: ['facilityResidentCount'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var apiParams, data_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!facilityType) return [3 /*break*/, 2];
                        apiParams = {
                            facility_id: params[facilitySlugs.facilityHomePage] || '',
                            facility_type: facilityType,
                        };
                        return [4 /*yield*/, getResidentCountService(apiParams)];
                    case 1:
                        data_4 = _a.sent();
                        return [2 /*return*/, data_4];
                    case 2: return [2 /*return*/, { blue: 0, gold: 0, green: 0, overall: 0 }];
                }
            });
        }); },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
        initialData: {
            blue: 0,
            gold: 0,
            green: 0,
            overall: 0,
            white: 0,
            total_residents: 0,
        },
        enabled: !!facilityType,
    }), data = _e.data, refetchResidentCount = _e.refetch;
    //holistic graph query
    var _f = useGetHolisticGraph({ graphState: graphState, facilityType: facilityType }), holisticGraphData = _f.data, isHolisticGraphLoading = _f.isLoading, isHolisticGraphRefetching = _f.isRefetching, refetchHolisticGraph = _f.refetch;
    //uCLALoneliness graph query
    // const {
    //   data: uCLALonelinessGraphData,
    //   isLoading: isUCLALonelinessGraphLoading,
    //   isRefetching: isUCLALonelinessGraphRefetching,
    //   refetch: refetchUCLALonelinessGraph,
    // } = useGetUCLALonelinessGraph({graphState, facilityType});
    //fallsAndHospitalization graph query
    // const {
    //   data: fallsAndHospitalizationGraphData,
    //   isLoading: isFallsAndHospitalizationGraphLoading,
    //   isRefetching: isFallsAndHospitalizationGraphRefetching,
    //   refetch: refetchFallsAndHospitalizationGraph,
    // } = useGetFallsAndHospitalizationGraph({graphState, facilityType});
    //homeSafety graph query
    // const {
    //   data: homeSafetyGraphData,
    //   isLoading: isHomeSafetyGraphLoading,
    //   isRefetching: isHomeSafetyGraphRefetching,
    //   refetch: refetchHomeSafetyGraph,
    // } = useGetHomeSafetyGraph({graphState, facilityType});
    //wellbeing graph query
    var _g = useGetWellbeingGraph({ graphState: graphState, facilityType: facilityType }), wellbeingGraphData = _g.data, isWellbeingGraphLoading = _g.isLoading, isWellbeingGraphRefetching = _g.isRefetching, refetchWellbeingGraph = _g.refetch;
    var residentCount = React.useMemo(function () {
        var zones = graphState.zones;
        var count = Object.values(CHART_ZONES)
            .filter(function (zoneKey) { return zones[zoneKey]; })
            .reduce(function (accumulator, currentValue) {
            return accumulator + ((data === null || data === void 0 ? void 0 : data[currentValue]) || 0);
        }, 0);
        return count;
    }, [data, graphState.zones]);
    /**
     * @description function to convert activityData to Graph Data
     * @function getGraphDataFromActivityGraphData
     * @param {IActivityGraphData[]} zoneData
     * @returns {IZoneGraphRecord[]} graphData
     */
    var getGraphDataFromActivityGraphData = React.useCallback(function (zoneData) {
        if (zoneData === void 0) { zoneData = []; }
        var graphData = zoneData === null || zoneData === void 0 ? void 0 : zoneData.map(function (_a) {
            var timestamp = _a.timestamp, score = _a.score;
            var d = new Date(timestamp);
            return {
                x: MONTHS[d.getUTCMonth()],
                y: score || 0,
            };
        });
        return graphData || [];
    }, []);
    /**
     * @description function to convert Fall data to Graph Data
     * @function getGraphDataFromFallGraphData
     * @param {IFallGraphData[]} zoneData
     * @returns {IZoneGraphRecord[]} graphData
     */
    var getGraphDataFromFallGraphData = React.useCallback(function (zoneData) {
        if (zoneData === void 0) { zoneData = []; }
        var graphData = zoneData === null || zoneData === void 0 ? void 0 : zoneData.map(function (_a) {
            var timestamp = _a.timestamp, score = _a.score;
            var d = new Date(timestamp);
            return {
                x: MONTHS[d.getUTCMonth()],
                y: score || 0,
            };
        });
        return graphData || [];
    }, []);
    /**
     * @description function to convert losData to Graph Data
     * @function getGraphDataFromLOSGraphData
     * @param {ILOSGraphData[]} zoneData
     * @returns {IZoneGraphRecord[]} graphData
     */
    var getGraphDataFromLOSGraphData = React.useCallback(function (zoneData) {
        if (zoneData === void 0) { zoneData = []; }
        var graphData = zoneData === null || zoneData === void 0 ? void 0 : zoneData.map(function (_a) {
            var timestamp = _a.timestamp, los = _a.los;
            var d = new Date(timestamp);
            return {
                x: MONTHS[d.getUTCMonth()],
                y: los || 0,
            };
        });
        return graphData || [];
    }, []);
    var activityData = React.useMemo(function () {
        var _a, _b, _c, _d;
        return {
            overall: getGraphDataFromActivityGraphData((_a = activityGraphData === null || activityGraphData === void 0 ? void 0 : activityGraphData.overall) === null || _a === void 0 ? void 0 : _a.data),
            blue: getGraphDataFromActivityGraphData((_b = activityGraphData === null || activityGraphData === void 0 ? void 0 : activityGraphData.blue) === null || _b === void 0 ? void 0 : _b.data),
            green: getGraphDataFromActivityGraphData((_c = activityGraphData === null || activityGraphData === void 0 ? void 0 : activityGraphData.green) === null || _c === void 0 ? void 0 : _c.data),
            gold: getGraphDataFromActivityGraphData((_d = activityGraphData === null || activityGraphData === void 0 ? void 0 : activityGraphData.gold) === null || _d === void 0 ? void 0 : _d.data),
        };
    }, [activityGraphData]);
    var fallData = React.useMemo(function () {
        var _a, _b, _c, _d;
        return {
            overall: getGraphDataFromFallGraphData((_a = fallGraphData === null || fallGraphData === void 0 ? void 0 : fallGraphData.overall) === null || _a === void 0 ? void 0 : _a.data),
            blue: getGraphDataFromFallGraphData((_b = fallGraphData === null || fallGraphData === void 0 ? void 0 : fallGraphData.blue) === null || _b === void 0 ? void 0 : _b.data),
            green: getGraphDataFromFallGraphData((_c = fallGraphData === null || fallGraphData === void 0 ? void 0 : fallGraphData.green) === null || _c === void 0 ? void 0 : _c.data),
            gold: getGraphDataFromFallGraphData((_d = fallGraphData === null || fallGraphData === void 0 ? void 0 : fallGraphData.gold) === null || _d === void 0 ? void 0 : _d.data),
        };
    }, [fallGraphData]);
    var losData = React.useMemo(function () {
        var _a, _b, _c, _d;
        return {
            overall: getGraphDataFromLOSGraphData((_a = losGraphData === null || losGraphData === void 0 ? void 0 : losGraphData.overall) === null || _a === void 0 ? void 0 : _a.data),
            blue: getGraphDataFromLOSGraphData((_b = losGraphData === null || losGraphData === void 0 ? void 0 : losGraphData.blue) === null || _b === void 0 ? void 0 : _b.data),
            green: getGraphDataFromLOSGraphData((_c = losGraphData === null || losGraphData === void 0 ? void 0 : losGraphData.green) === null || _c === void 0 ? void 0 : _c.data),
            gold: getGraphDataFromLOSGraphData((_d = losGraphData === null || losGraphData === void 0 ? void 0 : losGraphData.gold) === null || _d === void 0 ? void 0 : _d.data),
        };
    }, [losGraphData]);
    var _h = React.useMemo(function () {
        return getScoreAndTarget(graphState.zones, activityGraphData);
    }, [activityGraphData, graphState.zones]), activityScore = _h.score, activityTarget = _h.target;
    var activityTickValues = React.useMemo(function () {
        return getActivityChartTickValues(activityData, activityTarget);
    }, [activityData, activityTarget]);
    var fallScore = React.useMemo(function () {
        return getScoreAndTarget(graphState.zones, fallGraphData);
    }, [fallGraphData, graphState.zones]).score;
    var fallTickValues = React.useMemo(function () {
        return getFallChartTickValues(fallData);
    }, [fallData]);
    var los = React.useMemo(function () {
        return getScoreAndTarget(graphState.zones, losGraphData);
    }, [losGraphData, graphState.zones]).score;
    var losTickValues = React.useMemo(function () {
        return getLOSChartTickValues();
    }, []);
    React.useEffect(function () {
        if (facilityTypeOption) {
            setGraphState(function (state) { return (__assign(__assign({}, state), { facilityType: facilityTypeOption })); });
        }
        else if (facilityTypes === null || facilityTypes === void 0 ? void 0 : facilityTypes.length) {
            setGraphState(function (state) { return (__assign(__assign({}, state), { facilityType: facilityTypes.filter(function (facilityType) { return facilityType !== FACILITY_TYPES.ASSISTED_LIVING; })[0] })); });
        }
        else {
            setGraphState(function (state) { return (__assign(__assign({}, state), { facilityType: '' })); });
        }
    }, [facilityTypeOption, facilityTypes]);
    React.useEffect(function () {
        refetchResidentCount();
    }, [graphState.facilityType, refetchResidentCount]);
    React.useEffect(function () {
        refetchActivityGraph();
        if (graphState.facilityType === FACILITY_TYPES.MEMORY_CARE) {
            refetchFallGraph();
        }
        if (graphState.facilityType === FACILITY_TYPES.INDEPENDENT_LIVING) {
            refetchHolisticGraph();
            refetchWellbeingGraph();
            // refetchUCLALonelinessGraph();
            // refetchFallsAndHospitalizationGraph();
        }
        refetchLOSGraph();
    }, [
        graphState.months,
        graphState.facilityType,
        refetchActivityGraph,
        refetchLOSGraph,
        refetchFallGraph,
        refetchHolisticGraph,
        refetchWellbeingGraph,
        // refetchUCLALonelinessGraph,
        // refetchFallsAndHospitalizationGraph,
    ]);
    var disableFilters = React.useMemo(function () {
        return isActivityGraphLoading ||
            isActivityGraphRefetching ||
            isLOSGraphLoading ||
            isLOSGraphRefetching ||
            (graphState.facilityType === FACILITY_TYPES.MEMORY_CARE &&
                (isFallGraphLoading || isFallGraphRefetching)) ||
            (graphState.facilityType === FACILITY_TYPES.INDEPENDENT_LIVING &&
                (isHolisticGraphLoading || isHolisticGraphRefetching));
    }, [
        graphState.facilityType,
        isActivityGraphLoading,
        isActivityGraphRefetching,
        isFallGraphLoading,
        isFallGraphRefetching,
        isHolisticGraphLoading,
        isHolisticGraphRefetching,
        isLOSGraphLoading,
        isLOSGraphRefetching,
    ]);
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Activity Score Trend Explained" }), _jsx(Typography, { component: 'div', children: "This metric reflects the average activity score of all residents, derived from ClearWellness\u2019s unique point system. It encourages an average daily goal of 40,000 points for older adults, helping track and motivate community-wide engagement in physical activities. Dive into how your community is moving towards healthier living every day." })] }));
    var tooltipText1 = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Average Length of Stay Overview" }), _jsx(Typography, { component: 'div', children: "Track the average Length of Stay (LOS) in the ClearWellness service here. This metric showcases residents' typical durations within our supportive community, reflecting our commitment to fostering long-term well-being and tailored care for all." })] }));
    return (_jsxs(Box, { children: [_jsx(FacilityDetailHeader, { isResidentButton: true }), _jsxs(Box, { className: classes.container, children: [_jsx(Filters, { state: graphState, setState: setGraphState, residentCount: residentCount, disabled: disableFilters, currentFacilityType: facilityTypeOption, facilityTypes: facilityTypes }), _jsxs(Box, { className: classes.zoneGraphContainer, children: [(graphState.facilityType === FACILITY_TYPES.MEMORY_CARE ||
                                graphState.facilityType === FACILITY_TYPES.INDEPENDENT_LIVING) && (_jsx(ZoneChart, { state: graphState, data: activityData, title: _jsx(Tooltip, { title: tooltipText, placement: 'right', children: _jsxs(Typography, { variant: 'h6', children: ["Average Activity Score", ' ', _jsx(InfoIcon, { fontSize: 'medium', style: {
                                                    display: 'inline-flex',
                                                    marginLeft: '233px',
                                                    top: '5px',
                                                    position: 'relative',
                                                } })] }) }), current: true, currentLabel: 'Current Month Average:', currentValue: activityScore === null || activityScore === void 0 ? void 0 : activityScore.toLocaleString(), target: activityTarget, isLoading: isActivityGraphLoading || isActivityGraphRefetching, yAxisProps: {
                                    tickValues: activityTickValues,
                                    tickFormat: function (t) { return (t ? "".concat(Math.ceil(t / 1000), "k") : t); },
                                }, showTarget: true })), graphState.facilityType === FACILITY_TYPES.MEMORY_CARE && (_jsx(ZoneChart, { state: graphState, data: fallData, title: 'Average Fall Detection:', current: true, currentLabel: 'Current Month Average:', currentValue: fallScore === null || fallScore === void 0 ? void 0 : fallScore.toLocaleString(), isLoading: isFallGraphLoading || isFallGraphRefetching, yAxisProps: {
                                    tickValues: fallTickValues,
                                } })), graphState.facilityType === FACILITY_TYPES.INDEPENDENT_LIVING && (_jsx(WellbeingGraph, { graphState: graphState, queryData: {
                                    wellbeingGraphData: wellbeingGraphData,
                                    isWellbeingGraphLoading: isWellbeingGraphLoading,
                                    isWellbeingGraphRefetching: isWellbeingGraphRefetching,
                                } })), (graphState.facilityType === FACILITY_TYPES.MEMORY_CARE ||
                                graphState.facilityType === FACILITY_TYPES.INDEPENDENT_LIVING) && (_jsx(ZoneChart, { state: graphState, data: losData, title: _jsx(Tooltip, { title: tooltipText1, placement: 'right', children: _jsxs(Typography, { variant: 'h6', children: ["Average Length of Stay(LOS):", ' ', _jsx(InfoIcon, { fontSize: 'medium', style: {
                                                    display: 'inline-flex',
                                                    marginLeft: '169px',
                                                    top: '2px',
                                                    position: 'relative',
                                                } })] }) }), current: true, currentLabel: 'Current Month Average LOS:', currentValue: "".concat(los, " ").concat(los === 1 ? 'Day' : 'Days'), isLoading: isLOSGraphLoading || isLOSGraphRefetching, yAxisProps: {
                                    tickValues: losTickValues,
                                } }))] })] })] }));
};
export default FacilityDashboard;
