import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Box, Typography, useTheme, Tooltip } from '@mui/material';
import { holisticAssessmentStyle } from './HolisticAssessment.style';
import { residentDashboardStyle } from '../../ResidentDashboard.style';
import clsx from 'clsx';
import HolisticTable from './HolisticTable';
import InfoIcon from '@mui/icons-material/Info';
var HolisticAssessment = function () {
    var classes = holisticAssessmentStyle().classes;
    var residentDashboardClasses = residentDashboardStyle().classes;
    var theme = useTheme();
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Holistic Well-Being Assessment" }), _jsx(Typography, { component: 'div', children: "The holistic assessment evaluates residents' well-being across multiple areas, with scores ranging from 18 to 72. Higher scores reflect enhanced well-being levels. Assessments occur every three months to monitor progress in each section and overall well-being. The portal showcases residents' two most recent assessments for reference." })] }));
    return (_jsx(Box, { className: clsx(residentDashboardClasses.card, classes.cardContainer), children: _jsxs(Box, { className: classes.headingContainer, children: [_jsx(Typography, { variant: 'h6v1', color: theme.palette.customColor.black, children: "Holistic Assessment" }), _jsx(Tooltip, { title: tooltipText, children: _jsx(Box, { style: { display: 'inline-flex' }, children: _jsx(InfoIcon, { style: { marginLeft: '126px', position: "relative", top: "5px" }, fontSize: 'medium' }) }) }), _jsx(Typography, { mt: '12px', mb: 2, variant: 'subtitle2', children: "Score Range: 18-72" }), _jsx(HolisticTable, {})] }) }));
};
export default HolisticAssessment;
