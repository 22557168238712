/**
 * @class AssessmentHistoryModel
 * @description class to maintain the assessment history model
 */
var AssessmentHistoryModel = /** @class */ (function () {
    function AssessmentHistoryModel(date, time, totalScore, form, assessmentId, careAgentId, assessmentScore) {
        this.date = date;
        this.time = time;
        this.totalScore = totalScore;
        this.formData = form;
        this.assessmentId = assessmentId;
        this.careAgentId = careAgentId;
        this.assessmentScore = assessmentScore;
    }
    return AssessmentHistoryModel;
}());
export default AssessmentHistoryModel;
