var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import Table from 'common/Table/Table.component';
import useGetHolisticDashboard from './useGetHolisticDashboard';
import { Box, TableContainer, Typography } from '@mui/material';
import { API_LOAD_STATE, PRIMARY_COLOR } from 'globals/enums';
import { capitalizeFirstLetter } from 'globals/global.functions';
import { DATE_FORMAT_SHORT_YEAR } from 'globals/global.constants';
import { holisticAssessmentStyle } from './HolisticAssessment.style';
var HolisticTable = function () {
    var _a;
    var classes = holisticAssessmentStyle().classes;
    //get goals Data query
    var _b = useGetHolisticDashboard(), data = _b.data, isRefetching = _b.isRefetching;
    var tableData = React.useMemo(function () {
        var headerData = [
            {
                columnId: 'date',
                label: '',
                style: { paddingTop: '10px', paddingBottom: '10px' },
            },
        ], rowData = {
            values: [
                {
                    dataId: 'category',
                    format: function (value) { return value; },
                    style: {
                        paddingLeft: '8px',
                        width: '160px',
                        maxWidth: '160px',
                    },
                },
            ],
        }, totalScore = [];
        var categoryObj = {};
        data === null || data === void 0 ? void 0 : data.slice(0, 1).forEach(function (item) {
            var date = item.date;
            var time = item.time;
            var total = 0;
            // create table header Data
            headerData.push({
                columnId: 'date',
                label: moment(date).format(DATE_FORMAT_SHORT_YEAR),
                style: { paddingTop: '10px', paddingBottom: '10px' },
            });
            rowData.values.push({
                dataId: "".concat(date, "_").concat(time),
                format: function (value) { return value; },
                style: {
                    width: '20px',
                    maxWidth: '20px',
                },
            });
            // fetch all the unique categories
            Object.keys(item.assessmentScore).forEach(function (category) {
                var _a, _b;
                total += item.assessmentScore[category];
                categoryObj = __assign(__assign({}, categoryObj), (_a = {}, _a[category] = __assign(__assign({}, categoryObj[category]), (_b = { category: category
                            .split('_')
                            .map(function (item) { return capitalizeFirstLetter(item); })
                            .join(' ') }, _b["".concat(date, "_").concat(time)] = item.assessmentScore[category], _b)), _a));
            });
            totalScore.push(total);
        });
        return { headerData: headerData, rowData: rowData, data: Object.values(categoryObj), totalScore: totalScore };
    }, [data]);
    return (_jsxs(Box, { children: [_jsx(TableContainer, { className: classes.tableContainer, "data-testid": 'facilitySummaryTableContainer', children: _jsx(Table, { rowId: 'careInsightId', rowData: tableData.rowData, headerData: tableData.headerData, primaryColor: PRIMARY_COLOR.GREEN, data: tableData === null || tableData === void 0 ? void 0 : tableData.data, isDataLoading: isRefetching ? API_LOAD_STATE.PROGRESS : API_LOAD_STATE.SUCCESSFUL }) }), _jsxs(Box, { className: classes.bottomOverallGoal, children: [_jsx(Typography, { width: '120px', variant: 'subtitle2', children: "Total" }), _jsx(Box, { className: classes.totalScoreValues, children: (_a = tableData === null || tableData === void 0 ? void 0 : tableData.totalScore) === null || _a === void 0 ? void 0 : _a.map(function (value) { return (_jsx(Typography, { variant: 'subtitle2', width: '72px', children: value }, value)); }) })] })] }));
};
export default HolisticTable;
