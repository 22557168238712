import { DATE_FORMAT, TIME_FORMAT, } from '../../../../../globals/global.constants';
import moment from 'moment-timezone';
import AssessmentHistoryModel from '../model/assessmentHistoryModel';
var AssessmentHistoyParser = /** @class */ (function () {
    function AssessmentHistoyParser() {
        this.historyData = [];
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {IHistoryDataResponse} data api response array data
     * @returns {IHistoryDataParse[]} parsed data
     */
    AssessmentHistoyParser.prototype.parse = function (data) {
        this.historyData = data.map(function (history) {
            return new AssessmentHistoryModel(moment
                .utc(history.modification_date)
                .tz(moment.tz.guess())
                .format(DATE_FORMAT), moment
                .utc(history.modification_date)
                .tz(moment.tz.guess())
                .format(TIME_FORMAT), history.total_score, history.form, history.assessment_id, history.care_agent_id, history.assessment_score);
        });
        return this.historyData;
    };
    return AssessmentHistoyParser;
}());
export default AssessmentHistoyParser;
