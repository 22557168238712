var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable max-len */
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { showToast } from 'common/Toast';
import { getCurrentSenior } from 'globals/global.functions';
import { convertDateInUTC, convertEndDateInUTCTz, convertStartDateInUTCTz, getClientTimezone, subtractDate, validateDate, } from 'globals/date.functions';
import { TABLE_CACHE_KEY } from 'globals/global.constants';
import { getPaginationDataIsolated } from 'store/commonReducer/common.action';
import { getWellnessSurveyService } from 'services/assessmentService/wellnessSurvey/wellnessSurvey.service';
import moment from 'moment';
// actions for wellness survey
export var GET_WELLNESS_SURVEY = 'GET_WELLNESS_SURVEY';
export var GET_WELLNESS_SURVEY_SUCCESS = 'GET_WELLNESS_SURVEY_SUCCESS';
export var GET_WELLNESS_SURVEY_FAIL = 'GET_WELLNESS_SURVEY_FAIL';
export var UPDATE_TO_DATE = 'UPDATE_TO_DATE';
export var UPDATE_FROM_DATE = 'UPDATE_FROM_DATE';
export var IS_DATE_ERROR = 'IS_DATE_ERROR';
export var RESET_DATE_FILTER = 'RESET_DATE_FILTER';
export var RESET_WELLNESS_SCORE = 'RESET_WELLNESS_SCORE';
export var RESET_WELLNESS_SURVEY = 'RESET_WELLNESS_SURVEY';
export var CLEAR_SURVEYS = 'CLEAR_SURVEYS';
export var SET_ROWS = 'SET_ROWS';
export var UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
/**
 * @description action creator to fetch all the wellness surveys
 * @param {boolean} isCompleted
 * @param {number} limit
 * @returns void
 */
export var getWellnessSurvey = function (isCompleted, limit) {
    if (limit === void 0) { limit = 100; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var clientTimezone, seniorInfo, priorThirtyDayDate, _a, toDate, lastEvaluatedKey, fromDate, params, response, resLastEvaluatedKey, list, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    clientTimezone = getClientTimezone();
                    seniorInfo = __assign({}, getCurrentSenior());
                    dispatch(showApplicationLoader());
                    dispatch({ type: GET_WELLNESS_SURVEY });
                    priorThirtyDayDate = getPriorThirtyDayDate();
                    _a = getState().seniorDashboard.wellnessSurvey, toDate = _a.toDate, lastEvaluatedKey = _a.lastEvaluatedKey, fromDate = _a.fromDate;
                    params = {
                        senior_id: seniorInfo.seniorID,
                        account_id: seniorInfo.accountID,
                        limit: limit,
                        last_evaluated_key: lastEvaluatedKey,
                        is_survey_completed: isCompleted,
                        start_time: fromDate
                            ? convertStartDateInUTCTz(fromDate, clientTimezone)
                            : convertDateInUTC(priorThirtyDayDate),
                        // end_time: moment(toDate).tz('America/Los_Angeles').format(),
                        end_time: convertEndDateInUTCTz(toDate, clientTimezone),
                        ascending: false,
                    };
                    if (!lastEvaluatedKey) {
                        delete params.last_evaluated_key;
                    }
                    return [4 /*yield*/, getWellnessSurveyService(params)];
                case 1:
                    response = _b.sent();
                    dispatch(hideApplicationLoader());
                    resLastEvaluatedKey = response.data.last_evaluated_key;
                    list = response.data;
                    return [2 /*return*/, {
                            data: list,
                            lastEvaluatedKey: resLastEvaluatedKey,
                        }];
                case 2:
                    error_1 = _b.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(getWellnessSurveyFail(error_1));
                    return [2 /*return*/, new Error(error_1)];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getWellnessSurveySuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey;
    var scores = calulateWellnessScore(data);
    dispatch({
        type: GET_WELLNESS_SURVEY_SUCCESS,
        payload: {
            allSurveys: scores.survey,
            wellnessScore: scores.wellnessScore,
            lastEvaluatedKey: lastEvaluatedKey,
        },
    });
}; };
export var getWellnessSurveyFail = function (error) { return function (dispatch) {
    var message = '';
    if (error.response) {
        message = "".concat(GET_WELLNESS_SURVEY_FAIL, ": ").concat(error.response.data.message);
    }
    if (message) {
        dispatch(showToast(message, 'error'));
    }
    dispatch({
        type: GET_WELLNESS_SURVEY_FAIL,
    });
}; };
/**
 * @description actionCreator to validate the data and dispatch getWellnessSurvey
 * @returns void
 */
export var getSurvey = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var priorThirtyDayDate, _a, toDate, fromDate, isDateError, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                priorThirtyDayDate = getPriorThirtyDayDate();
                _a = getState().seniorDashboard.wellnessSurvey, toDate = _a.toDate, fromDate = _a.fromDate, isDateError = _a.isDateError;
                if (!fromDate) {
                    dispatch(updateFromDate(priorThirtyDayDate));
                }
                if (!!validation(fromDate || priorThirtyDayDate, toDate, isDateError)) return [3 /*break*/, 5];
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, dispatch(getWellnessSurvey(true))];
            case 2: return [2 /*return*/, _b.sent()];
            case 3:
                error_2 = _b.sent();
                return [2 /*return*/, null];
            case 4: return [3 /*break*/, 6];
            case 5:
                dispatch(updateIsDateError(true));
                _b.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}); }; };
export var applyDateFilter = function () { return function (dispatch, getState) {
    dispatch(clearSurveyData());
    var totalRows = getState().seniorDashboard.wellnessSurvey.totalRows;
    setTimeout(function () {
        dispatch(getPaginationDataIsolated(getSurvey, totalRows, TABLE_CACHE_KEY.WELLNESS_SURVEY, 1, getWellnessSurveySuccess, getWellnessSurveyFail, '', ''));
    }, 200);
}; };
var clearSurveyData = function () { return function (dispatch) {
    dispatch(updateCurrentPage(1));
    localStorage.removeItem(TABLE_CACHE_KEY.WELLNESS_SURVEY);
    dispatch({ type: CLEAR_SURVEYS });
}; };
/**
 * @description action creator to update the "to" date
 * @param {date} value
 * @returns void
 */
export var updateToDate = function (value) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({ type: UPDATE_TO_DATE, payload: { date: value } });
        return [2 /*return*/];
    });
}); }; };
/**
 * @description action creator to update the "from" date
 * @param {date} value
 * @returns void
 */
export var updateFromDate = function (value) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({ type: UPDATE_FROM_DATE, payload: { date: value } });
        return [2 /*return*/];
    });
}); }; };
/**
 * @description action creator to update the "isDateError"
 * @param {boolean} value error true or false
 * @returns void
 */
export var updateIsDateError = function (value) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({ type: IS_DATE_ERROR, payload: value });
        return [2 /*return*/];
    });
}); }; };
/**
 * @description action creator to reset the date filters value
 * @param {date} value
 * @returns void
 */
export var resetDateFilter = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var priorThirtyDayDate;
    return __generator(this, function (_a) {
        priorThirtyDayDate = getPriorThirtyDayDate();
        localStorage.removeItem(TABLE_CACHE_KEY.WELLNESS_SURVEY);
        dispatch({ type: RESET_DATE_FILTER, payload: { priorThirtyDayDate: priorThirtyDayDate } });
        dispatch(applyDateFilter());
        return [2 /*return*/];
    });
}); }; };
/**
 * @description action creator to reset wellness survey
 * @returns void
 */
export var resetWellnessSurvey = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var currentSeniorId;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        currentSeniorId = (_c = (_b = (_a = getState().common) === null || _a === void 0 ? void 0 : _a.seniorDetail) === null || _b === void 0 ? void 0 : _b.minimalInfo) === null || _c === void 0 ? void 0 : _c.user_id;
        localStorage.removeItem("".concat(TABLE_CACHE_KEY.WELLNESS_SURVEY, "-").concat(currentSeniorId));
        dispatch({ type: RESET_WELLNESS_SURVEY });
        return [2 /*return*/];
    });
}); }; };
/**
 * @description action creator to update the table current page
 * @returns void
 */
export var updateCurrentPage = function (value) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({ type: UPDATE_CURRENT_PAGE, payload: value });
        return [2 /*return*/];
    });
}); }; };
/**
 * @description fn to validate the dates
 * @param {date} fromDate start date
 * @param {date} toDate end date date
 * @param {boolean} isDateError error value
 * @returns void
 */
var validation = function (fromDate, toDate, isDateError) {
    var isError = false;
    if (!validateDate(fromDate) || !validateDate(toDate) || isDateError) {
        isError = true;
    }
    return isError;
};
export var calulateWellnessScore = function (survey) {
    survey.forEach(function (data) {
        var answerKeysArr = Object.keys(data.answer);
        data.score = 0;
        data.scoreLimit = answerKeysArr.length;
        answerKeysArr.forEach(function (dataKey) {
            var _a;
            if (!((_a = data.answer[dataKey]) === null || _a === void 0 ? void 0 : _a.value)) {
                data.score += 1;
            }
        });
    });
    return { survey: survey };
};
export var wellnessTableDimensions = {
    oneRowHeight: 55,
    tableHeaderHeight: 103,
};
/**
 * @function getPriorThirtyDayDate
 * @returns {Moment}prior 30 days date
 */
export var getPriorThirtyDayDate = function () { return subtractDate(moment(), 30, 'days'); };
