import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { InputFields } from '../InputFields';
import React from 'react';
import { uCLALonelinessSectionStyles } from './UCLALonelinessSection.style';
import { withStyles } from 'tss-react/mui';
import { getScores } from 'common/Print/Print.utility';
var getValue = function (radioData) {
    if (radioData.some_of_the_time == 1) {
        return 'Some of the time';
    }
    else if (radioData.hardly_ever == 1) {
        return 'Hardly Ever';
    }
    else if (radioData.often == 1) {
        return 'often';
    }
    else {
        return '';
    }
};
var TableCell = withStyles(MuiTableCell, {
    root: {
        borderBottom: 'none',
        padding: '5px 0',
    },
});
var UCLALonelinessSection = function (props) {
    var _a = props.heading, heading = _a === void 0 ? '' : _a, _b = props.data, data = _b === void 0 ? [] : _b;
    var dataArray = Array.isArray(data) ? data : [];
    var maxScore = React.useMemo(function () { return data.length * 3; }, [dataArray]);
    var totalScore = React.useMemo(function () {
        return dataArray.reduce(function (acc, b) { return acc + getScores(b); }, 0);
    }, [dataArray]);
    var classes = uCLALonelinessSectionStyles().classes;
    return (_jsxs(Box, { component: 'section', className: classes.section, "data-testid": 'print-holistic-section', children: [_jsxs(Box, { className: classes.header, children: [_jsx("div", { className: classes.heading, children: heading }), _jsx("div", { className: classes.tableHeading, style: { paddingRight: '30px' }, children: "Total(Max Score is ".concat(maxScore, ")= ").concat(totalScore) })] }), _jsx(Box, { className: classes.container, children: _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx("div", { style: { width: '1580px' } }) }), _jsx(TableCell, { align: 'right', children: _jsxs(Box, { className: classes.radioGroupHeader, children: [_jsx(Box, { className: classes.tableHeading, children: "Hardly Ever" }), _jsx(Box, { className: classes.tableHeading, children: "Some of the Time" }), _jsx(Box, { className: classes.tableHeading, children: "Often" })] }) })] }) }), _jsx(TableBody, { children: dataArray.map(function (row, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { component: 'th', scope: 'row', children: _jsxs(Box, { className: classes.tableContent, children: [_jsxs(Box, { children: [index + 1, ".)"] }), _jsxs(Box, { children: [row.question, _jsx("span", { style: { color: '#cc0000' }, children: "*" })] })] }) }), _jsx(TableCell, { children: _jsx(Box, { display: 'flex', justifyContent: 'space-between', width: '520px', padding: '0 36px', children: _jsx(InputFields, { className: classes.radioGroup, radio: true, inputProps: {
                                                        name: 'row.question',
                                                    }, eventProps: {
                                                        value: getValue(row),
                                                    }, radioItems: [
                                                        { value: 'Hardly Ever', label: 'Hardly Ever' },
                                                        { value: 'Some of the time', label: 'Some of the time' },
                                                        { value: 'often', label: 'often' },
                                                    ] }) }) })] }, row.question)); }) })] }) }) })] }));
};
export default UCLALonelinessSection;
