/**
 * @class HolisticGraphModel
 * @description class to maintain the Holistic graph data
 */
var HolisticGraphModel = /** @class */ (function () {
    function HolisticGraphModel(overall, blue, green, gold) {
        this.overall = overall;
        this.blue = blue;
        this.green = green;
        this.gold = gold;
    }
    return HolisticGraphModel;
}());
export default HolisticGraphModel;
