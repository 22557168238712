import { makeStyles } from 'tss-react/mui';
export var holisticAssessmentStyle = makeStyles()(function (theme) { return ({
    cardContainer: {
        width: '395px',
        height: '414px',
        position: 'relative',
    },
    headingContainer: {
        padding: '12px 20px',
    },
    contentContainer: {},
    totalScoreValues: {
        width: '161px',
        display: 'flex',
        gap: '15px',
    },
    tableContainer: {
        height: '248px',
        borderRadius: '5px 5px 0px 0px',
        border: 'solid 1px #cecece',
        overflow: 'auto',
        borderBottom: 'none',
    },
    bottomOverallGoal: {
        height: '50px',
        padding: '0px 10px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '0px 0px 5px 5px',
        backgroundColor: theme.palette.background.green,
        border: 'solid 1px #cecece',
        borderTop: 'none',
    },
}); });
